<template>
  <v-card>
    <v-card-title>
      {{ setting ? setting.name : 'Error' }}
    </v-card-title>

    <v-card-text v-if="setting.parameters">
      <v-container>
        <v-col>
          <v-row
            v-for="(params, paramName) in setting.parameters"
            :key="paramName"
          >
            <v-col class="col-4">
              {{ paramName }}
            </v-col>

            <v-col>
              <v-row
                v-for="(param, paramIndex) in params"
                :key="paramIndex"
              >
                <v-col class="col-3 py-0">
                  {{ param.operator }}
                </v-col>
                <v-col class="py-0">
                  {{ param.value }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-3">
                  <v-row v-if="!editing">
                    <v-btn
                      icon
                      @click="addFilterSetting(paramName)"
                    >
                      <v-icon>pencil</v-icon>
                    </v-btn>
                  </v-row>

                  <v-row>
                    <v-col
                      v-if="entry"
                      class="operator-button pr-2"
                    >
                      <v-row>
                        <v-col>
                          <div
                            :class="`cursor-pointer font-weight-bold ${getOperatorColor(entry)}--text`"
                            @click="toggleOperator(entry)"
                          >
                            {{ getOperatorLabel(entry) }}
                          </div>
                        </v-col>

                        <v-col>
                          <v-text-field
                            v-model="entry.value"
                            autofocus
                            dense
                            flat
                            height="25px"
                            hide-details
                            solo
                            @keyup.esc="cancelAction"
                            @keyup.enter="updateSettings"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mt-3">
            <v-spacer />

            <v-btn
              small
              text
              class="ml-2 primary--text"
              color="primary"
              :disabled="isSubmittingUpdate"
              name="updateSettings"
              @click="cancelAction"
            >
              Cancel
            </v-btn>

            <v-btn
              small
              class="ml-2 white--text"
              color="primary"
              :loading="isSubmittingUpdate"
              name="updateSettings"
              @click="updateSettings"
            >
              Update
            </v-btn>
          </v-row>
        </v-col>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import operators from 'src/utils/operators'

const { mapGetters, mapState } = createNamespacedHelpers('internal')

export default {
  name: 'SettingsModal',
  props: {
    setting: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    currentOperators: operators.textual,
    editing: null,
    isSubmittingUpdate: false,
    isValidForm: true,
  }),
  computed: {
    ...mapGetters([
      'errorMessage',
      'humans',
      'successMessage',
    ]),
    ...mapState({
      organizations: (state) => state.organizations,
      person: (state) => state.person,
      persons: (state) => state.persons,
    }),
    entry: {
      get() {
        if (!this.editing) return this.setting
        return this.editing
      },
      set(newValue) {
        this.editing = newValue
      },
    },
    rulesRequired() {
      return [(value) => !!value || 'Required']
    },
  },
  watch: {
    person(newValue) {
      if (newValue) {
        // do something
      }
    },
  },
  methods: {
    addFilterSetting() {
      this.entry = { ...this.setting }
      this.editing = true
    },
    cancelAction() {
      this.editing = false
      this.entry = null
    },
    async updateSettings() {
      const { isValidForm, filters, settings } = this
      const { commit, dispatch } = this.$store
      const entry = { ...settings, filters }

      if (!isValidForm) {
        const message = 'Invalid Form'

        commit('tools/snackbar', true)
        commit('tools/snack', { message, status: 'error' })

        return false
      }

      const response = await dispatch('internal/updateUser', entry)
      const { error, message, status } = response

      if (error) {
        commit('tools/snackbar', true)
        commit('tools/snack', { message, status })

        return error
      }

      if (status === 'success') {
        this.cancelAction()
      }

      commit('tools/snackbar', true)
      commit('tools/snack', { message, status })

      return response
    },
    getOperatorColor(option) {
      const { operator } = option
      const { currentOperators } = this
      const { defaults } = operators
      const { color } = currentOperators[operator] || defaults

      return color
    },
    getOperatorLabel(item) {
      const { currentOperators } = this
      const { defaults } = operators
      const { operator = defaults.textual } = item

      return currentOperators[operator].label
    },

    toggleOperator(option) {
      const { currentOperators, type } = this
      const { defaults } = operators
      const { operator = defaults.textual } = option

      const { [operator]: config } = currentOperators
      const { next } = config

      const nextOperator = next[type]

      return nextOperator
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

::v-deep.v-card {
  border-radius: 6px;
  border-width: 0;
}

::v-deep.v-card__title {
  color: $color-monochrome-darkestblue;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.51px;
  line-height: 22px;
  justify-content: space-between;
}

::v-deep.v-card__text {
  padding: 0;
}

.v-card + .v-card {
  margin-top: 1ch;
}
</style>
