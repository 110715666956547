<template>
  <v-row class="ma-3 justify-center">
    <v-col class="col-9">
      <v-card
        class="px-3 pt-3 pb-6 elevation-0"
      >
        <v-card-title class="darkblue--text">
          {{ person.personName }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-row class="subtitle-2">
                <v-col class="midgray--text">
                  ID
                </v-col>
              </v-row>
              <v-row class="body-2 mt-0">
                <v-col class="py-0 font-weight-medium nearblack--text">
                  {{ person.personId }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row class="subtitle-2">
                <v-col class="midgray--text">
                  Email
                </v-col>
              </v-row>
              <v-row class="body-2 mt-0">
                <v-col class="py-0 font-weight-medium nearblack--text">
                  {{ person.personEmail }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="px-3 pt-3 pb-6 elevation-0">
        <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
          Profile
        </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col>
              <v-row class="subtitle-2">
                <v-col class="midgray--text">
                  Status
                </v-col>
              </v-row>

              <v-row
                :class="`body-2 mt-0 ${isActiveColor}--text`"
              >
                <v-col class="py-0 font-weight-medium">
                  {{ isActiveLabel }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row class="subtitle-2">
                <v-col class="midgray--text">
                  Level
                </v-col>
              </v-row>
              <v-row class="body-2 mt-0">
                <v-col class="py-0 font-weight-medium nearblack--text">
                  {{ level }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row class="subtitle-2">
                <v-col class="midgray--text">
                  Role
                </v-col>
              </v-row>
              <v-row class="body-2 mt-0">
                <v-col class="py-0 font-weight-medium nearblack--text">
                  {{ role }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row class="subtitle-2">
                <v-col class="midgray--text">
                  Networks
                </v-col>
              </v-row>
              <v-row class="body-2 mt-0">
                <v-col class="py-0 font-weight-medium nearblack--text">
                  {{ networks }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="isInternal">
              <v-row class="subtitle-2">
                <v-col class="midgray--text">
                  Manager
                </v-col>
              </v-row>
              <v-row
                class="body-2 mt-0"
              >
                <v-col class="py-0 font-weight-medium">
                  <router-link :to="`/internal/person/${person.managerPersonId}`">
                    {{ person.managerPersonName }}
                  </router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="px-3 pt-3 pb-6 elevation-0">
        <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
          Settings
        </v-card-subtitle>

        <v-card-text
          v-if="settings && settings.length"
          class="body-2"
        >
          <v-card
            v-for="(settingType, settingName) in settings"
            :key="settingName"
            class="mb-2 elevation-1"
          >
            <v-toolbar
              color="offwhite"
              class="elevation-0"
            >
              <v-toolbar-title class="subtitle-2 text-capitalize">
                {{ settingName }}
              </v-toolbar-title>

              <v-spacer />

              <!-- <v-btn
                icon
                @click="viewEditForm"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn> -->
            </v-toolbar>

            <v-list>
              <v-list-group
                v-for="(setting, settingIndex) in settingType"
                :key="settingIndex"
                v-model="setting.active"
                :prepend-icon="setting.action"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="body-2 font-weight-medium"
                      v-text="setting.name"
                    />
                  </v-list-item-content>
                </template>

                <v-list-item v-if="setting.parameters">
                  <v-col>
                    <v-row v-if="canEditSettings">
                      <v-dialog
                        v-model="showSettingsForm"
                        scrollable
                        persistent
                        max-width="500px"
                        transition="dialog-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-spacer />

                          <v-btn
                            color="primary lighten-2"
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            Edit
                          </v-btn>
                        </template>

                        <settings-form :setting="setting" />
                      </v-dialog>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col>
                            <div class="font-weight-bold">
                              Name
                            </div>
                            <div>
                              {{ setting.name || 'Unnamed' }}
                            </div>
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="setting.network"
                        >
                          <v-col>
                            <div class="font-weight-bold">
                              Networks
                            </div>
                            <div
                              v-for="(network, networkIndex) in setting.network"
                              :key="networkIndex"
                            >
                              {{ getOrgAndTeamName(network) }}
                            </div>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <div class="font-weight-bold">
                              Reports
                            </div>
                            <div>
                              {{ setting.reports.join(', ') }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="font-weight-bold">
                        <v-row>
                          <v-col class="col-4 py-0">
                            Property
                          </v-col>
                          <v-col class="col-2 py-0">
                            Operator
                          </v-col>
                          <v-col class="py-0 font-weight-medium">
                            Value
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row
                      v-for="(params, paramName) in setting.parameters"
                      :key="paramName"
                    >
                      <v-col class="col-4 pt-0">
                        {{ paramName }}
                      </v-col>

                      <v-col class="mb-6">
                        <v-row
                          v-for="(param, paramIndex) in params"
                          :key="paramIndex"
                        >
                          <v-col class="col-3 py-0">
                            {{ param.operator }}
                          </v-col>
                          <v-col class="py-0 font-weight-medium">
                            {{ param.value }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item>

                <v-list-item v-else>
                  {{ JSON.stringify(setting) }}
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card>
        </v-card-text>

        <v-card-text v-else>
          None
        </v-card-text>
      </v-card>

      <v-card
        class="px-3 pt-3 pb-6 elevation-0"
      >
        <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
          Change Log
        </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col>
              <v-row class="body-2">
                <v-col class="py-0 font-weight-medium">
                  <span class="pr-1 midgray--text">Created</span>

                  <span class="nearblack--text">
                    {{ createdOn }}
                  </span>

                  <span class="px-1 midgray--text">by</span>

                  <span class="nearblack--text">
                    {{ createdBy }}
                  </span>
                </v-col>
              </v-row>

              <v-row
                v-if="updatedBy"
                class="body-2"
              >
                <v-col class="py-0 font-weight-medium">
                  <span class="pr-1 midgray--text">Updated</span>

                  <span class="nearblack--text">
                    {{ updatedOn }}
                  </span>

                  <span class="px-1 midgray--text">by</span>

                  <span class="nearblack--text">
                    {{ updatedBy }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import dates from 'src/utils/dates'
import SettingsForm from './SettingsForm'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapState: mapInternalState } = createNamespacedHelpers('internal')
const { mapState: mapLayoutState } = createNamespacedHelpers('layout')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'PersonProfile',
  components: {
    SettingsForm,
  },
  data() {
    return {
      colorMap: {
        false: 'toasted',
        true: 'midgray',
        'n/a': 'midgray',
        undefined: 'midgray',
      },
      entry: {},
    }
  },
  computed: {
    ...mapAuthGetters(['isUserInternalAdmin']),
    ...mapToolsGetters(['currentViewId', 'teams']),
    ...mapLayoutState({
      icons: (state) => state.icons,
      valueIcons: (state) => state.icons.values,
    }),
    ...mapToolsState({
      selectedSearch: (state) => state.selectedSearch,
    }),
    ...mapInternalState({
      didLoadPersonFail: (state) => state.didLoadPersonFail,
      isSettingsModalVisible: (state) => state.isSettingsModalVisible,
    }),
    person() {
      const { selectedSearch } = this
      const personUnknown = {
        access: { permissions: {} },
        personNameFirst: 'Unknown',
        personNameLast: 'Person',
        settings: {},
      }

      return selectedSearch || personUnknown
    },
    canEditSettings() {
      // return this.isUserInternalAdmin
      return false
    },
    isDeleted() {
      const { person = {} } = this
      const { isDeleted } = person

      return isDeleted
    },
    isActive() {
      const { person = {} } = this
      const { access } = person
      const { active } = access

      return active
    },
    isActiveColor() {
      const { isActive, valueIcons } = this
      const status = String(isActive)
      const { color = 'nearblack' } = valueIcons[status] || {}

      return color
    },
    isActiveLabel() {
      if (this.isDeleted) return 'Deleted'
      return this.isActive ? 'Active' : 'Inactive'
    },
    isInternal() {
      const { person = {} } = this
      const { access } = person
      const { role } = access

      return role === 'internal'
    },

    networks() {
      const { person = {} } = this
      const { network: networks } = person

      if (!networks?.length) return 'Not Assigned'

      return networks.map((network) => {
        const { organization, team } = network
        const { code: billingCode } = organization || {}

        if (!billingCode) return 'ERROR_CONFIG_ORGANIZATION'

        return team.map((t) => {
          const teamCode = t.code

          if (!teamCode) return 'ERROR_CONFIG_TEAM'

          if (teamCode === '-1') return billingCode

          return `${billingCode} (Team ${t.code})`
        }).join(', ')
      }).join(', ')
    },
    level() {
      const { person = {} } = this
      const { access } = person
      const { level } = access

      return level
    },
    role() {
      const { person = {} } = this
      const { access } = person
      const { role } = access

      return role
    },
    settings() {
      const { person = {} } = this
      const { settings = [] } = person

      if (typeof settings !== 'object') {
        return { error: 501, details: person, layer: 'data', message: 'Corrupted' }
      }

      return settings
    },
    showSettingsForm: {
      get() {
        return this.isSettingsModalVisible
      },
      set(newValue) {
        this.$store.commit('internal/isSettingsModalVisible', newValue)
      },
    },
    createdBy() {
      const { person = {} } = this
      const unknownActor = 'Unknown'
      const { assoc_email = unknownActor, updated_dt } = person

      return !updated_dt ? assoc_email : unknownActor
    },
    createdOn() {
      const { person = {} } = this
      const { created_dt } = person

      return dates.getFormattedLocalDateTime(created_dt)
    },
    updatedBy() {
      const { person = {} } = this
      const { assoc_email } = person

      return assoc_email
    },
    updatedOn() {
      const { person = {} } = this
      const { updated_dt } = person

      return dates.getFormattedLocalDateTime(updated_dt)
    },
  },
  methods: {
    getOrgAndTeamName(network) {
      const orgTeam = this.teams.find((team) => team.billingCode === network.billingCode && team.teamId === network.teamId && team.teamCode === network.teamCode)
      return orgTeam?.teamName || 'Unknown Team Name'
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__subtitle {
  letter-spacing: 0.025rem;
}
</style>
